.carousel {
  max-width: 600px;
  margin: auto;
}

.carousel-item img {
  object-fit: cover;
  object-position: center;
  height: 400px;
  overflow: hidden;
}